/* eslint-disable no-nested-ternary */
const environment = {
  prod: {
    awsconfig: {
      aws_project_region: 'ca-central-1',
      // aws_cognito_identity_pool_id: 'ca-central-1:6caff98b-cd88-4792-b199-161e5deabbd1',
      aws_cognito_region: 'ca-central-1',
      aws_user_pools_id: 'ca-central-1_72AvhBx7V',
      aws_user_pools_web_client_id: '314ca2pa0o0f7lk5a3nkoga45j',
      oauth: {},
    },
    nodeAPI: 'https://api-mobile.skiinserver.com',
    webSocketLink: 'wss://prod-websocket.skiinserver.com',
    enablePendo: true,
    dynamoDBResources: {
      symptomLog: 'DynamoDB#Prod-SymptomLog',
      symptomAnalysis: 'DynamoDB#Prod-SymptomAnalysis',
    },
  },
  dev: {
    awsconfig: {
      aws_project_region: 'us-east-1',
      // aws_cognito_identity_pool_id: 'us-east-1:793672161147:userpool/us-east-1_bHvuIv5IZ',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_bHvuIv5IZ',
      aws_user_pools_web_client_id: '5gesh7lup6jopc33qfq00up8pi',
      oauth: {},
    },
    nodeAPI: 'https://api-mobile.dev.skiinserver.com/api',
    webSocketLink: 'wss://lxwkunc4vj.execute-api.us-east-1.amazonaws.com/dev',
    enablePendo: true,
    dynamoDBResources: {
      symptomLog: 'DynamoDB#SymptomLog',
      symptomAnalysis: 'DynamoDB#SymptomAnalysis',
    },
  },
  stag: {
    awsconfig: {
      aws_project_region: 'us-east-2',
      aws_cognito_region: 'us-east-2',
      aws_user_pools_id: 'us-east-2_OTiEEMId8',
      aws_user_pools_web_client_id: '6scnfqt542jioueldthut5g6q4',
      oauth: {},
    },
    nodeAPI: 'https://api-mobile.stag.skiinserver.com/api',
    webSocketLink: 'wss://ruwq31rxjb.execute-api.us-east-2.amazonaws.com/dev',
    enablePendo: true,
    dynamoDBResources: {
      symptomLog: 'DynamoDB#SymptomLog',
      symptomAnalysis: 'DynamoDB#SymptomAnalysis',
    },
  },
  cnc: {
    awsconfig: {
      aws_project_region: 'ca-central-1',
      aws_cognito_region: 'ca-central-1',
      aws_user_pools_id: 'ca-central-1_QnuzZXgpg',
      aws_user_pools_web_client_id: '313q97m0v7tc89nrd9jbgknobb',
      oauth: {},
    },
    nodeAPI: 'https://api-mobile.cnc.skiinserver.com/api',
    webSocketLink: 'wss://novfowyvrd.execute-api.ca-central-1.amazonaws.com/cnc',
    enablePendo: true,
    dynamoDBResources: {
      symptomLog: 'DynamoDB#SymptomLog',
      symptomAnalysis: 'DynamoDB#SymptomAnalysis',
    },
  },
  variables: {
    webSocketDataTypes: {
      heartRate: 'heartRate',
      rhr: 'rhr',
      ecgOne: 'ecgOne',
      ecgTwo: 'ecgTwo',
      ecgThree: 'ecgThree',
    },
    webSocketCategoris: {
      heartHealth: 'heartHealth',
    },
    HeartRate: 'HeartRate',
    Activity: 'Activity',
    Steps: 'Steps',
    Temperature: 'Temperature',
    EMPTY_METRICS_VALUE: '--',
    API_CHUNK_SIZE: 25,
    miliSecondsInADay: 86400000,
    nexJURL: 'https://app2.connectedwellness.com/ui/login/main?goto=%252F&org=pace.pace',
  },
};

// eslint-disable-next-line no-nested-ternary
let currentENV = 'dev';

if (window.location.hostname.includes('scwp.skiinserver.com')) currentENV = 'prod';
if (window.location.hostname.includes('scwp.stag.skiinserver.com')) currentENV = 'stag';
if (window.location.hostname.includes('scwp.cnc.skiinserver.com')) currentENV = 'cnc';

export const config = environment[currentENV];

export const { variables } = environment;

// Parser constants
export const HIGH_BYTE_FACTOR = 4294967296; // 2 ** 32
/// //////////// BLE command codes
export const COMMAND_OPCODES = {
  RTC_RESET_OPCODE: [0x02, 0x00],
  STEPS_RESET_OPCODE: [0x09, 0x00],
  STEPS_THRESHOLD_OPCODE: [0x08, 0x00],
  SET_GARMENT_ID_OPCODE: [0x01, 0x91],
  SET_POD_NAME_OPCODE: [0x00, 0x91],
  SET_SERIAL_NUMBER_OPCODE: [0x01, 0x99],
  GET_SERIAL_NUMBER_OPCODE: [0x00, 0x99],
  IDENTIFY_POD_OPCODE: [0x11, 0x00],
  GET_SECRET_NUMBER_OPCODE: [0x04, 0x99],
  UNLOCK_POD_OPCODE: [0x94, 0xed],
  READ_POD_IV_OPCODE: [0x90, 0xed],
  READ_STORED_DATA_OPCODE: [0x00, 0x90],
  STOP_STORAGE_READ_OPCODE: [0x01, 0x90],
  ERASE_STORED_DATA_OPCODE: [0x02, 0x90],
  ENABLE_AND_DISABLE_STORAGE_OPCODE: [0x10, 0x90],
  SET_LEAD_SETTLING_TIME_OPCODE: [0x02, 0x91],
  GET_LEAD_SETTLING_TIME_OPCODE: [0x03, 0x91],
  ENABLE_AND_DISABLE_LEAD_DETECTION_OPCODE: [0x13, 0x00],
  KEEP_ALIVE: [0x14, 0x00],
};
/// //////////// BLE characteristics
export const SKIIN_SERVICE_UUID = '20046800-9630-4ea8-9d21-04148452e81f';
// Heat
export const HEAT_SERVICE_UUID = '20046200-9630-4ea8-9d21-04148452e81f';
export const STORED_METRICS = {
  STEPS: 0,
  STEPS_DATA_SIZE: 13,
  ACTIVITY: 1,
  ACTIVITY_DATA_SIZE: 6,
  ACTIVITY_DATA_SIZE_LARGE: 10,
  TEMPERATURE: 2,
  TEMPERATURE_DATA_SIZE: 11,
  ECG_CH1: 3,
  ECG_CH1_DATA_SIZE: 33,
  ECG_CH2: 4,
  ECG_CH2_DATA_SIZE: 33,
  ECG_CH3: 5,
  ECG_CH3_DATA_SIZE: 33,
  METRICS: 100,
  ECG: 101,
};

export const COOKIE = {
  GROUP_NAME: 'grpName',
  GROUP_ID: 'grpId',
  GROUP_INFO_TTL: '365', // number of days
};

const aBS = require('./a_bs.json');
const bBS = require('./b_bs.json');
const aLP = require('./a_lp.json');
const bLP = require('./b_lp.json');
const aHP = require('./a_hp.json');
const bHP = require('./b_hp.json');
const aRPeakHP = require('./a_rpHP.json');
const bRPeakHP = require('./b_rpHP.json');

export const filterCoefficients = {
  lowPass: {
    a: aLP,
    b: bLP,
  },
  highPass: {
    a: aHP,
    b: bHP,
  },
  bandStop: {
    a: aBS,
    b: bBS,
  },
  rPeakHP: {
    a: aRPeakHP,
    b: bRPeakHP,
  },
};

export const hrThresholds = {
  desired: 75,
  acceptable: 50,
};

export const hrRanges = {
  low: 30,
  high: 200,
};

export const ecgPadLengths = {
  prePad: 3200,
  postPad: 3200,
};

export const ecgSamplingRate = 320;

export const roles = {
  CARDIAC_TECH: 'CardiacTech',
  PATIENT: 'patient',
  MD: 'MD',
  WELLNESS_COACH: 'Wellness Coach',
  DESK_ADMIN: 'DeskAdmin',
  ADMIN: 'admin',
};
